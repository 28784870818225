import React from 'react';
import CalculatorIcon from "../node/img/calculator.png"
import CovidIcon from "../node/img/covid.png"
import QuoteIcon from "../node/img/quote.png"
import BlogIcon from "../node/img/blog.png"
import GradeIcon from "../node/img/grade.png"
import NewsIcon from "../node/img/news.png"
import UrgentCareIcon from "../node/img/urgentcare.png"
import StoreIcon from "../node/img/store.png"
import BotIcon from "../node/img/bot.png"

import Card from "./Card"



const Projects = ()=>    {



const CalculatorImage = CalculatorIcon;
const UrgentCareImage = UrgentCareIcon;
const BotImage = BotIcon;
const StoreImage = StoreIcon;



   const    appTitle=[
          { name: 'Calculator App', description: 'Simple and cute react calculator with basic functions.',
        status: 'Live', website:'/calculator', github:'https://github.com/kennashka/calculator'}, 
          { name: 'Covid-19 App', description: 'Covid-19 App using axios library, and novelCovid API to display current cases',
          status: 'Live', website:'http://covid.kennashka.com/', github:'https://github.com/kennashka/covid-19'}, 
          { name: 'To Do/Reminder App', description: 'To do List App made with react.',
           status: 'Live', website:'/todo', github:'https://github.com/kennashka/reminder-app'}, 
          { name: 'Kenya Blog (In Dev)', description: 'Kenya React Blog with file upload functionality.',
          status: 'In Development', website:'http://wall.kennashka.com/', github:'https://github.com/kennashka/react-photo-blog-app'}, 
          { name: 'Grade Average App', description: 'A Node.js project that calculates the average of three grades.', 
          status: 'Live', website:'/grade', github:'https://github.com/kennashka/grade-average-calculation'}, 
          { name: 'News App', description: 'React news app with search functionality using hacker news api',
          status: 'Live', website:'/news', github:'https://github.com/kennashka/react-news-app'}, 
          { name: 'Hialeah Urgent Care', description: ' Whitepaper on how to secure an urgent care',
          status: 'In Development', website:'/urgentcare', github:'/#'}, 
          { name: 'Store (In Dev)', description: ' Kenya Ecommerce Store',
           status: 'In Development', website:'http://store.kennashka.com', github:'/#'}, 
          { name: 'Bot App', description: 'Robotic Car Project ',
          status: 'Live', website:'https://techsociety.info', github:'https://github.com/project-sd/'}, 
      
         
         ]
    return (
        
<div>
 <div data-reactroot className="row">



     {/* card 1 */}


 <div>
     <div className="col m4">
     <div className="card hoverable">

     <div className="card-image waves-effect waves-block waves-light">
     <img className="activator" 
     src= {CalculatorImage} alt='calculator'
     style={{maxWidth: '100px'
     , maxHeight: '100px'}} />
     </div>
     
     <div className="card-content">
     
     
         
    <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div>

     
      
      <span className="card-title activator grey-text text-darken-4">
  Calculator App
      <i className="material-icons right">more_vert</i>
      </span>
      
      <div>
 <span>
    <a className="website" href="/calculator">Website</a>
  </span>

  <span>
      <a className="github" href="https://github.com/kennashka/calculator">Github</a> 
        </span> 
  </div> 
   </div>
 <div className="card-reveal">
  <span className="card-title grey-text text-darken-4"> Calculator App <i className="material-icons right">close</i></span>
  <div>
      <span className="status"> Live</span>
 </div>
 <p> Simple and cute react calculator with basic functions. </p>
 </div>
</div>
  </div>
     {/* card 1 */}
 
              
              <div className="col m4"><div className="card hoverable">
              <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" 
              src= {CovidIcon}  
              alt='covid'
              style={{maxWidth: '100px', maxHeight: '100px'}} />
              </div><div className="card-content">
              <div>
                  <div>

                  <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>

              
              </div>
              
              </div>
              
              <span className="card-title activator grey-text text-darken-4">
              Covid-19 App<i className="material-icons right">more_vert</i>
              </span>
              <div>
                  <span>
                      <a className="website" href="http://covid.kennashka.com/">Website</a>
            </span>
            
            <span>
                <a className="github" href="https://github.com/kennashka/covid-19">Github
                </a>
            </span>
            </div>
            </div>
                
                <div className="card-reveal">
                <span className="card-title grey-text text-darken-4">
                Covid-19 App
                <i className="material-icons right">close</i>
                </span>
                
                <div>
                    <span className="status">Live</span>
                    </div>
                    <p>               Covid-19 App using axios library, and novelCovid API to display current cases
 </p>
                    </div>
                    </div>
                    </div>
                    <div className="col m4">
                    <div className="card hoverable">
                    <div className="card-image waves-effect waves-block waves-light">
                    <img className="activator" 
                    src= {QuoteIcon} 
                    style={{maxWidth: '100px', maxHeight: '100px'}} 
                    />
                    
                    </div>
                    <div className="card-content">
                    
                    <div>
                        <div>
                        <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div>
                            
                            </div>
                            </div>
                            
                            <span className="card-title activator grey-text text-darken-4">
              
              To Do/Reminder App
              <i className="material-icons right">more_vert</i>
              </span><div><span><a className="website" href="/todo">Website</a>
              </span><span><a className="github" href="https://github.com/kennashka/reminder-app">
              Github</a></span></div></div><div className="card-reveal">
              <span className="card-title grey-text text-darken-4">
              To Do/Reminder App
              <i className="material-icons right">close</i></span><div><span className="status">
              Live</span>
              </div>
              <p>To do List App made with react.</p>
              </div>
              </div>
              </div>
              <div className="col m4">
              <div className="card hoverable">
              <div className="card-image waves-effect waves-block waves-light">
              <img className="activator" 
              src={BlogIcon} 
              style={{maxWidth: '100px', maxHeight: '100px'}} />
              </div>
              <div className="card-content">
              <div>
                  <div><div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div></div>
                  </div>
                  <span className="card-title activator grey-text text-darken-4">
                  {/* react-text: 106 */}Kenya Blog (In Dev){/* /react-text */}<i className="material-icons right">more_vert</i></span><div>
                      
                      <span><a className="website" href="http://wall.kennashka.com/">Website</a>
                      </span>
                      <span>
                          <a className="github" href="https://github.com/kennashka/react-photo-blog-app">Github</a>
                          
                          </span>
                          </div>
                          </div>
                          <div className="card-reveal">
                          <span className="card-title grey-text text-darken-4">Kenya Blog (In Dev)<i className="material-icons right">close</i></span>
                          <div>
                              <span className="status">In Development</span>
                              </div>
                              <p>Kenya React Blog with file upload functionality. </p>
                              </div>
                              </div>
                              </div>
                              <div className="col m4">
                              <div className="card hoverable"><div className="card-image waves-effect waves-block waves-light">
                          
                          <img className="activator" src= {GradeIcon} style={{maxWidth: '100px', maxHeight: '100px'}} />
                          </div>
                          <div className="card-content">
                          <div>
                              <div>
                              <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div>
                          </div>
                          
                          </div>
                          <span className="card-title activator grey-text text-darken-4">
                          Grade Average App<i className="material-icons right">more_vert</i>
                          
                          </span><div><span><a className="website" href="/grade">Website</a>
                          
                          </span><span><a className="github" href="https://github.com/kennashka/grade-average-calculation">Github</a>
                          </span>
                          </div>
                          </div>
                          <div className="card-reveal">
                          <span className="card-title grey-text text-darken-4">Grade Average App<i className="material-icons right">close</i>
                          
                          </span><div><span className="status">Live</span>
                          </div><p>A Node.js project that calculates the average of three grades. </p>
                          
                          </div>
                          </div>
                          </div>
                          <div className="col m4">
                          <div className="card hoverable">
                          <div className="card-image waves-effect waves-block waves-light">
                          <img className="activator" 
                          src= {NewsIcon} 
                          style={{maxWidth: '100px', maxHeight: '100px'}} />
                          
                          </div>
                          <div className="card-content">
                          <div>
                              <div>
                              <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div>
                              </div>
                              </div>
                  <span className="card-title activator grey-text text-darken-4">News App
                  <i className="material-icons right">more_vert</i>
                  </span>
                  <div>
                      <span>
                          <a className="website" href="/news">Website</a>
                          </span><span>
                              <a className="github" href="https://github.com/kennashka/react-news-app">Github</a>
                              </span></div></div><div className="card-reveal">
                              <span className="card-title grey-text text-darken-4">
                              {/* react-text: 172 */}News App{/* /react-text */}
                              <i className="material-icons right">close</i>
                              </span><div><span className="status">In Development</span>
                              </div><p>React news app with search functionality using hacker news api </p>
                              </div>
                              </div>
                              </div>
                              </div>

                              <Card CardImage={UrgentCareImage} appTitle={appTitle[6]}/>
                              <Card CardImage={StoreImage} appTitle={appTitle[7]}/>
                              <Card CardImage={BotImage} appTitle={appTitle[8]}/>



                             
 </div>
</div>

    );
        };

    
export default Projects;



