import React, { useState}  from 'react';
import Header from '../core/Header';
import Footer from '../core/Footer';
import './css/style.css';

// import './core/grade/img/zen.jpg';
// import './core/grade/js/index.js';

const Grade = () => {
  //const [grade, setGrade] = useState({one: '', two: '', three: '', average: grade.one + grade.two})

  const [one, setOne] = useState("");
  const [two, setTwo] = useState(""); 
  const [three, setThree] = useState(""); 
  const [total, setTotal] = useState(((one + two + three)/3) );

  function calculateTotal(e) {
    e.preventDefault();
    setTotal(((one + two + three)/3) );
    var avg = (((one + two + three)/3));
    if (avg >=90){document.getElementById("an").innerHTML="You got an A. 💅🏾💅🏾💅🏾"} 
    else if (avg >= 80){document.getElementById("an").innerHTML="You got a B. 🤳🏾🤳🏾🤳🏾"} 
    else if (avg >=70){document.getElementById("an").innerHTML="You got a C. 💁🏾‍♀️💁🏾‍♀️💁🏾‍♀️ "}
    else if (avg >=60){document.getElementById("and").innerHTML="You got a D.🙅🏾‍♀️🙅🏾‍♀️🙅🏾‍♀️"}  
    else if (avg <=59){document.getElementById("and").innerHTML="You got a F. 🤷🏾‍♀️🤷🏾‍♀️🤷🏾‍♀️"} 
    console.log(avg)
  }


const clear = (e) => { 
e.preventDefault();

document.getElementById("an").innerHTML=""
document.getElementById("and").innerHTML=""
  setOne('');
  setTwo('');
  setThree('');
  setTotal('')

}

 

  return (
    <div className="backg">
  <Header>
  </Header>

  <div className="container p-5">
        <div className="card w-50 mx-auto bg-light">
          <div className="card-header bg-primary text-center"> <b>Average/Mean Calculator</b></div>
          <div className="card-body">
          <form id="create-course-form">
            <div className="form-group">
              <label>What's Your First Grade?</label>
              <input 
              className="form-control" 
              type="text" 
              value = {one}
              onChange={e => setOne(+e.target.value)}
              // id={1} 
              placeholder="Input 1st #" />
            </div>
            <div className="form-group">
              <label> What About Your Second Grade? </label>
              <input 
              className="form-control" 
              type="number"
              value = {two}
              onChange={e => setTwo(+e.target.value)}
              //  id={2} 
              placeholder="Input 2st #" 
              />
            </div>
            <div className="form-group">
              <label>And Third? </label>
              <input 
              className="form-control" 
              type="number" 
              value = {three}
              onChange={e => setThree(+e.target.value)}
              // id={3} 
              placeholder="Almost there 3rd #" />
            </div>
            <button className="btn btn-secondary" type="button" onClick={calculateTotal}>Calculate</button>
            <button className="btn btn-secondary"  onClick={clear}>Reset</button>
            <p className="p-3">
              Calculated Grade: <span id="ans" style={{color: 'green'}} /> {total}
            </p>
            <div className="wrapper">
              <p> <span id="an" style={{color: 'green'}} /> <span id="and" style={{color: 'red'}} /></p>
            </div> {/* Answer Result Div*/} 
            </form>
          </div> {/* card body*/}
        </div> {/* card*/}
      </div> {/*container*/}
      {/*Background Image*/}

    <Footer>
    </Footer>
    </div>
  );
}

export default Grade;
