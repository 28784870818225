import React from 'react';
import Header from '../core/Header';
import Footer from '../core/Footer'; 
import './UrgentCare.css'
import data1  from '../node/img/data1.png';
import data2  from '../node/img/data_dup.png';
import data3  from '../node/img/data2.png';
import hardware  from '../node/img/hardware.png';
import threat1  from '../node/img/threatanalysis.png';
import threat2  from '../node/img/threat_analy.png';
import threat3  from '../node/img/threat_analy2.png';
import threat4  from '../node/img/threat4.png';
import network  from '../node/img/network_diagram.png';
import asset  from '../node/img/asset.png';


// import * as s from './UrgentCare.styles';


const UrgentCare = () =>{

return(
<div>
        <Header/>


        <article>
          
        <div className="toc">
          <h2>Table of Contents</h2>
          <h4>Authors: Kennashka DeSilva, Elianys Roca, Johnathon Campos, Jose Dario Lara, and Jorge Alejandro Brito</h4>

          <ol>
            <li>
              <a href="#pro1" title="Go to Problem Definition I">
                <span className="chapter">Problem Definition I</span>
                <span className="toc-page">02</span>
              </a>
              <ol>
                <li><a href="#pro1_1" title="Go to Description of the Business Context"><span className="chapter">Description of the Business Context	</span>
                    <span className="toc-page">02</span></a></li>
                <li><a href="#pro1_2" title="Go to Scope"><span className="chapter">Scope</span>
                    <span className="toc-page">02</span></a></li>
                    <li><a href="#pro1_3" title="Go to Asset Inventory"><span className="chapter">Asset Inventory</span>
                    <span className="toc-page">02</span></a></li>
              </ol>
            </li>
            
            <li><a href="#pro2" title="Go to Problem Definition II"><span className="chapter">Problem Definition II</span>
                <span className="toc-page">03</span></a>
                <ol>
                <li><a href="#pro2_1" title="Go to Threat Analysis"><span className="chapter">Threat Analysis	</span>
                    <span className="toc-page">04</span></a></li>
                <li><a href="#pro2_2" title="Go to Current Security Posture	"><span className="chapter">Current Security Posture</span>
                    <span className="toc-page">06</span></a></li>
  
              </ol>
            </li>
            <li><a href="#design1" title="Go to Design & Specification I	?"><span className="chapter">Design & Specification I	</span>
                <span className="toc-page">08</span></a>
              <ol>
                <li><a href="#design1_1" title="Go to Design Approach"><span className="chapter">Design Approach	</span>
                    <span className="toc-page">09</span></a></li>
                <li><a href="#design1_2" title="Go to Design and Development"><span className="chapter">Design and Development	</span>
                    <span className="toc-page">09</span></a></li>
                    <li><a href="#design1_3" title="Go to Encryption and File Password-protection"><span className="chapter">Encryption and File Password-protection	</span>
                    <span className="toc-page">10</span></a></li>
                <li><a href="#design1_4" title="Go to Residual Risk and Other Considerations	"><span className="chapter">Residual Risk and Other Considerations		</span>
                    <span className="toc-page">10</span></a></li>
              </ol>
            </li>
            <li><a href="#design2" title="Go to Design & Specification II	"><span className="chapter">Design & Specification II	</span>
                <span className="toc-page">11</span></a>
                <ol>
                <li><a href="#design2_1" title="Go to Selected Security Controls	"><span className="chapter">Selected Security Controls		</span>
                    <span className="toc-page">12</span></a></li>
               
              </ol>
            </li>
            
            <li><a href="#implement" title="Go to Implementation	"><span className="chapter">Implementation</span>
                <span className="toc-page">13</span></a>
                <ol>
                <li><a href="#implement_1" title="Go to Selected Security Control	"><span className="chapter">Selected Security Control		</span>
                    <span className="toc-page">14</span></a></li>
                    <li><a href="#implement_2" title="Go to Implementation of Security Control	"><span className="chapter"> Implementation of Security Control		</span>
                    <span className="toc-page">15</span></a></li>
                    <li><a href="#implement_3" title="Go to Summary of Proposed Policies in Accordance with HIPAA Guidelines		"><span className="chapter">Summary of Proposed Policies in Accordance with HIPAA Guidelines			</span>
                    <span className="toc-page">15</span></a></li>
                    
              </ol>
            </li>

            <li><a href="#conclusion" title="Go to Conclusion	"><span className="chapter">Conclusion	</span>
                <span className="toc-page">16</span></a>
             
            </li>
          </ol>
        </div>
        {/* end .toc */}
        <hr />
      </article>
<div className='container'>
      <h2 id='pro1'> Problem Definition I</h2>
      <h4 id='pro1_1'>Description of the Business Context</h4>
      <p>Established in 2020, Hialeah Urgent Care (HUC) is an urgent care medical facility providing medical treatment and care for the Hialeah and Hialeah gardens residents. Their business model is to provide low-level emergency care services to their patients, so they do not have to visit a hospital or schedule appointments with their primary care physicians.

Due to the current pandemic, the constant cyberattacks affecting healthcare companies, and to adhere to federal and state guidelines, HUC is looking to evaluate and implement a more secure, robust, and scalable Remote Desktop Services (RDS) remote access solution for both internal and external staff members.  Their goal is to be able to provide telemedicine services without the hassle of managing standalone workstations in the office.  Additionally, a great number of staff members have been moved to telework to mitigate the risk of a COVID-19 outbreak in the medical facility, thus requiring efficient remote access solutions. Finally, due to Health Insurance Portability and Accountability Act (HIPAA) regulations, they want to ensure that they abide by the established guidelines set by HIPAA. 

Because of this, they have hired a team of IT consultants to evaluate, design, and implement the right remote desktop solution to this current challenge. 
 </p>

 <h4 id='pro1_2'>Scope</h4>
<p> The objectives of Hialeah Urgent Care are to:

Implement best-practices and strategies regarding RDS solutions and distinguish issues that the facility faces when responding to a cyber breach pertaining to managing standalone workstations. Because of the scope of the project, the following items will not be evaluated:
</p>

<li>Hardware/firmware of the infrastructure</li> 
<li>Network security devices/software from the infrastructure </li>
<li> Managed services such as Databases </li>
<li> Cloud-based software solutions </li>

<p><b> Assets that will not be addressed in this project: </b> </p>
<li> Physical Security of the facility  </li>
<li> IP Phones  </li>
<li> IoT Devices  </li>
<li> Cameras  </li>
<li> Printers </li>
<li> Medical devices  </li>
<li> Local networking devices/software </li>


<h4 id='pro1_3' className='pt-3'>Asset Inventory</h4>
<p>The table below indicates the assets that will be considered:</p>
<div className="container">
<div className="col center">
    <img src={data1} alt="" className="center img-fluid"
          height={650}
          width={550}></img>
    </div>
    <div className="col center">
    <img src={data3} alt="" className="center img-fluid"
          height={100}
          width={550}></img>
    </div>  
</div>
<div className="container">
  <div className="row">
  
    <div className="col pt-3">
    <img src={data2} alt="" className="center img-fluid"
          height={150}
          width={400}></img>
    </div>
    <div className="col pt-3">
    <img src={hardware} alt="" className="center img-fluid"
          height={110}
          width={400}></img>
    </div>
  
  </div>
</div>

<h2 id='pro2'> Problem Definition II</h2>
<h4 id='pro2_1'>Threat Analysis</h4>
<p>The following table shows an exhaustive list of applicable threats identified for each asset:
</p>


<div className="col center">
    <img src={threat1} alt="" className="center img-fluid"
          height={850}
          width={600}></img>
    </div>
    <div className="col center">
    <img src={threat4} alt="" className="center img-fluid"
          height={400}
          width={600}></img>
    </div>



<p>Hialeah Urgent Care has decided that the following strategies will be used to address the identified threats.</p>
 
<div className="col center pb-5">
    <img src={threat2} alt="" className="center img-fluid"
          height={150}
          width={500}></img>
    </div>
<h4 id='pro2_2'>Current Security Posture</h4>
<p>
Hialeah Urgent Care (HUC) is in the middle of drastic technological and infrastructure migration due to the pandemic.  Their current security posture is fundamentally based on their brick-and-mortar services that are heavily dependent on on-premises services.  Additionally, their security standing consists of ensuring that their physical location is secure and that IT resources are only available on premises; Due to a significant number of staff members having switched to telework, they lack the infrastructure to meet their current technology and business needs. 

</p>

<p>
The following policies were established by their IT provider:

</p>
 <li> On-premises storage via SharePoint </li>
 <li> No Cloud services (with exception of the EMR) </li>
 <li> On-premises Domain Controller: Workstation - Server architecture  </li>
 <li> No VPN (Network Isolation) </li>
 <li> No virtualization in place </li>
 <li> Strict firewall rules to ensure there is no remote access to IT resources. </li>
 <li> Remote monitoring solution in place: Patching, Antivirus</li>
 <li> Monthly workstation / server / network equipment patching. </li>
 <li> Strict access to facilities managed via Keycard entry systems.   </li>
 <li> Quarterly staff training. </li>
 <li> Password policies directly managed by HR department.  </li>
 <li> Third-party service support agreement for medical devices. </li>


<h2 id='design1'>Design & Specification I</h2>
<h4 id='design1_1'>Design Approach</h4>
<p>
For this solution, we will be designing and developing a strategic IT solution that encompasses the industries best practices. With this in mind, we believe that focusing on the least privilege and need to know principles will provide a solid foundation for the security and successful implementation of the project. 
According to the least privilege principle, we must ensure that only the bare minimum rights to resources should be provided to users in general.  In addition, some of these rights might only be needed for a certain amount of time. For example, the user might be working on a specific project that, at completion, will no longer require specific access to resources.  On the other hand, when users are provided with access to information beyond the scope of a project or their daily duties, we run the risk of giving access to confidential information that can compromise the entity. This is of particular importance since we need to ensure that HIPAA, federal and state guidelines are properly followed.  By carefully assigning the proper permissions to users, we can mitigate these and future risks.

</p>
 
 <h4 id='design1_2'>Design and Development</h4>

<p>

To begin with, we will be discussing and evaluating the proper access to resources with different teams and their leaders to ensure that the project follows the appropriate guidelines set by HIPAA. We will be using the tools embedded within the Active Directory system in the windows server.
</p>
<p>Multi-group users </p>
<p>
Due to the dynamics of the business at HUC, we will be implementing different security groups and subgroups to encompass different resource access scenarios. We will accommodate these needs by creating subgroups in the windows active directory as demonstrated in the example below:
</p>
<li>Human Resources security group (main group)</li>
<li>Manager (subgroup)</li>
<li>Staff members (subgroup)</li>



 <h4 id='design1_3' className='pt-4'>Encryption and File Password-protection
</h4>

<p>
Following the guidelines of least privilege principle, it is important to consider both encryption and password-protection for individual files. Data encryption will prevent or deter unauthorized access to information. File password protection will enhance this mechanism by adding an extra layer of security both internally at HUC and whenever this information is transmitted to other entities (including patients) via digital delivery.
</p>

 <h4 id='design1_4'>Residual Risk and Other Considerations
</h4>

<p>
For the scope of the project, the administrative and physical safeguards as well as residual risks will be passed onto the corresponding teams at HUC. 

Regarding HIPAA, this solution will concentrate on the technical safeguards of data and other resources. These safeguards consist of implementing policies and procedures to protect and limit access to Electronic Protected Health Information, also known as ePHI.  We will design this application considering the important aspects that the CIA triad is comprised of:

Confidentiality – We will implement this aspect by controlling access to the data. 

Integrity – We will ensure that the quality of the data is not compromised by faulty security mechanisms. 

Availability – We will ensure uptime and access of critical components of the infrastructure both internally and externally. 



</p>
<h2 id='design2'>Design & Specification II
</h2>
 <h4 id='design2_1'>Selected Security Controls
</h4>
<p>The following table shows an exhaustive list of applicable threats for each identified asset and the recommended security controls for each one:</p>
<div className="col center">
    <img src={asset} alt="" className="center img-fluid"
          height={900}
          width={600}></img>
    </div>
 
   <div className="col center">
    <img src={threat3} alt="" className="center img-fluid"
          height={500}
          width={600}></img>
    </div>


<p>According to the HIPAA Security rule, a thorough vulnerability analysis of the application of said security controls is recommended.</p>



 
    <div className="col center pb-5">
    <img src={network} alt="" className="center img-fluid"
          height={550}
          width={600}></img>
    </div>


<h2 id='implement'>Implementation
</h2>


 <h4 id='implement_1'>Selection of Security Control
</h4>
<p>

For this project, we chose to design, develop, and implement a security control that will help prevent, detect, respond, and recover from malware attacks.

The primary reason we selected this security control is the increasing number of malware attacks in healthcare settings.  In the year 2020, the COVID-19 pandemic created a perfect storm for cyber criminals and malware attacks, costing the industry north of $21 Billion US dollars in downtime and about 18 million patient records compromised in the process.  In fact, 2020 had the most ransomware attacks in the past 5 years, resulting in cybercriminals collecting more than $2.1 million dollars in ransom payments.

As a response to this threat, our group has developed a multilayered Malware control solution based on the <b>Zero trust principle: Never trust, Always Verify. </b> This principle lies on granting the least amount of access needed to perform an activity within the organization.

We divided this solution into 4 individual components that will help us achieve this goal and provide the maximum level of protection against malware for HUC:

</p>

<li>Endpoint Security System – This will allow us to effectively manage entry points AKA end points such as mobile devices, desktops, laptops, etc.  
</li>
<li>
Intrusion Detection System (IDS) – An IDS will help us analyze, monitor, and filter network traffic in order to detect vulnerabilities, exploits, and other types of attacks designed to be carried by targeting specific assets within the organization.
</li>
<li>
Intrusion Prevention System (IPS) – Will be the primary system in charge of preventing identified threats. In conjunction with an IDS system, the IPS will not only be able identify threats, but can also stop incoming / outbound data packets, thus, reducing the chances of communications with compromised hosts in the network by using backdoors.  
</li>
<li>
Firewall – The firewall will manage inbound and outgoing network traffic based on a predefined set of security configurations, rules, and exceptions.
</li>
 <h4 id='implement_2'>Implementation of Security Control
</h4>
<p>
In order to build a cost / effective remote access solution, we have decided to implement an all-in-one endpoint security, IDS, and IPS solution for the remote desktops. Integrating IDSs and IPSs is convenient for this environment as we are working with 35 workstations and do not require any larger and more expensive solutions. 

Integration of our all-in-one solution will be achieved by installing endpoint security agents on each remote desktop, a server agent on the RDS server, and a cloud management solution to monitor, manage, and deploy changes in the organization.
 
Because HUC needs to be compliant with HIPAA guidelines, we must enforce strong cybersecurity policies to safeguard the company’s digital assets.  These policies will be enforced via policy management in the cloud management solution. 

We assume that users will be using only corporate issued devices to access corporate resources.

</p>
<h4 id='implement_3'>Summary of Proposed Policies in Accordance with HIPAA Guidelines

</h4>

<p>
Although we will be focusing primarily on the malware security control, we must iterate the need to implement machine learning that can recognize whether files and applications are either malicious or benign. This can be done by preemptively analyzing them, applying pattern detection algorithms, and reporting these findings to the Cybersecurity administrative team.

The following is a list of policies set forth in compliance with HIPAA guidelines:

</p>
<b>
Endpoint Security
</b>
<li>
Advanced Heuristics </li>
<li>
Live grid database contains reputation information about current potential threats worldwide. 
</li>
<li>
External devices such as flash drives and external hard drives will be immediately blocked once plugged in. Exceptions to this rule will need to be submitted to the IT management.
</li>
<li>
Network traffic management, we will block all traffic except connections via VPN.</li>
<li>
Implement Machine learning and deep behavioral inspection against viruses. </li>
<li>
Dynamic Threat Defense to automatically submit scripts and executables for review and scan.</li>
<li>
Endpoint agent’s software will run in silent mode. </li>
<li>
Scheduled scans will be performed daily at the end of business hours. 
</li>


<b>
IDS / IPS
</b>
<li>
Ability to monitor and correlate log data from different systems. </li>
<li>
Ability to generate alerts when suspicious activity or policy violations are detected.
</li>
<li>
Ability to monitor systems integrity and generate alerts when file system changes are made. 
</li>
<li>
Monitoring network traffic patterns that might affect availability of services such as a DDoS.
</li>
<li>
Enable Network attack protection via IDS / IPS configuration.
</li>


<h2 id='conclusion'>
Conclusion

</h2>
<p>
Hialeah Urgent Care is a medical facility that is looking 
to implement a more secure and scalable Remote Desktop Services (RDS) solution for all 35 of its employees all the while closely following HIPAA guidelines. 
They have hired a team of IT Consultants, to evaluate, design, and implement the right remote desktop solution for their company.

After a thorough threat analysis, we designed a multilayered malware control solution that included the following four security components, each of which, were in accordance with HIPAA guidelines:

<li>Endpoint Security System </li>
<li>Intrusion Detection System (IDS) </li>
<li>Intrusion Prevention System (IPS) </li>
<li>Firewall</li>

Integration of all four of these c
omponents will consist of installing endpoint s
ecurity agents on each remote desktop, a server age
nt on the RDS server, and a cloud management solution to 
monitor, manage, and deploy changes in the organization.

<p>Advantages of the solution:</p>

<p>- Security/Functionality: Users will experience the 
  maximum level of security while still being able to 
  operate at the highest functionality level with ease.
</p>
<p>- Cost: Due to the size of the company, this all-in-one implementation, provides a low cost yet effective solution.
</p>
Our team had the opportunity to learn some new and cost-efficient technology solutions that will allow us to better assist our future clients, especially during this current pandemic.

</p>
 </div>
     <Footer/> 
        </div>
)

}

export default UrgentCare;