import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom"; 
//browser router will make the props availabe to other components
import App from "./home/App";
 import Calculator from "./calculator/Calculator";
 import Todo from "./todo/Todo";
// import Blog from "./Blog";
import Grade from "./grade/Grade";
import News from "./news/News";
import UrgentCare from "./urgentcare/UrgentCare";




const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={App} />
                <Route path="/todo" exact component={Todo} />
                <Route path="/News" exact component={News} />
                <Route path="/Grade" exact component={Grade} />
                <Route path="/Calculator" exact component={Calculator} /> 
                <Route path="/urgentcare" exact component={UrgentCare} /> 

                {/* <Route path="/Blog" exact component={Blog} />  */}
            </Switch> 
        </BrowserRouter>
    );
};

export default Routes;