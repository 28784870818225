import React from 'react';
import img from '../node/img/node.jpg';
import Projects from '../core/Projects.js';
import '../node/css/style.css';
import Footer from "../core/Footer"
function App() {
  
  return (
    
    <div>


       <header>

       <meta name="description" content="Kennashka DeSilva online portfolio and skillset." />
        <meta name="keywords" content="kennashka Desilva" />

        <div className="left-header">
          <div className="name" style={{  fontFamily:'roboto',
  fontSize:'27px'}}>Kennashka DeSilva</div>
          <div className="description">Full Stack Developer </div>
        </div>
        <div className="right-header"><a href="#projects">Projects</a> | <a href="#skills">Expertise</a>
          | <a target="”_blank&quot;"  rel="noopener noreferrer" href="https://app.acuityscheduling.com/schedule.php?owner=16888995&notembedded=1">Contact</a> </div>
        {/* end of header info */}

        
      </header>
   

    <section id="home">
      <div className="overlay">
        {/* PRIMARY NAVIGATION */}
        <div className="container">
          <h1 className="text-center p-5"><small>Web / Automation Development</small></h1>
          <ul className="social-links vertical-list">
            <li><a href="https://github.com/kennashka" className="noir github" target="_blank" rel="noopener noreferrer">Github</a></li>
          
          </ul>
        </div>
      </div>
    </section>
    <div className="container">
      <section id="projects">
        {/* Three columns of text below the carousel */}
        <div className="col-md-12">
          <h2 className="featurette-heading pt-5">Projects. <span className="text-muted">Code Repository.</span></h2>
          <div>
            <div className="container">
              <p id="title" />
              <div className="row">
              <Projects>
</Projects>
              </div>
            </div>
          </div>
        </div>
      </section>

  
      <section id="skills">

  

        <div className="container"> 
          <div className="row p-2">
            <h2 className="featurette-heading">Expertise. <span className="text-muted">Keeps getting better.</span></h2>
            <p className="lead pb-5">
              Knowledge-base on scripting languages ( Python, SQL, C++, Javascript, Jquery, HTML, CSS, XML, Bash, Node.Js, Angular JS, Git and JSON.)</p>
            <div className="col">
              <img className="cover" src={require ("../node/img/python_icon.png")} width={100} height={100}  alt="python icon"/>
            </div>
            <div className="col">
              <img className="cover" src={require ("../node/img/sql-icon.png")} width={100} height={100} />
            </div>
            <div className="col">
              <img className="cover" src= {require ("../node/img/bash.jpg")} width={100} height={100} />
            </div>
            <div className="col">
              <img className="cover" src= {require ("../node/img/javascript.png")} width={100} height={100} />
            </div>
          </div>
          <div className="row p-2">
            <div className="col">
              <img src= {require ("../node/img/jquery.png")} width={100} height={100} />
            </div>
            <div className="col">
              <img src= {require ("../node/img/html.png")} width={100} height={100} />
            </div>
            <div className="col">
              <img src={ require ('../node/img/css.png')} width={100} height={100} />
            </div>
            <div className="col">
              <img src={img} width={100} height={100} />
            </div>
          </div>
          <div className="row p-2">
            <div className="col">
              <img src= {require ( "../node/img/AngularJS-Shield.svg")} width={100} height={100} />
            </div>
            <div className="col">
              <img src= {require ("../node/img/git.png")} width={100} height={100} />
            </div>
            <div className="col">
              <img src= {require ("../node/img/c++ icone.png")} width={100} height={100} />
            </div>
            <div className="col">
              <img src= {require ("../node/img/xml.jpeg")} width={100} height={100} />
            </div>
          </div>
        </div>
      </section>

      
      {/* /END THE FEATURETTES */}
      {/* Footer */}


      <section>
        <h2 className="featurette-heading">Contact.</h2>
      </section></div>

<Footer/>
    
    </div>
  );
}

export default App;
