import React from 'react';

export const  getYear =()=> {
  return new Date().getFullYear();
}

const Footer = () =>{



    return (

<footer className="page-footer unique-color-dark">
      <div style={{backgroundColor: '#6351ce'}}>
        <div className="container">
          {/* Grid row*/}
          <div className="row py-4 d-flex align-items-center">
            {/* Grid column */}
            <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
              <h6 className="mb-0 text-white">Get connected with me on social networks!</h6>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-md-6 col-lg-7 text-center text-white text-md-right">
              {/* Github */}
              <a className="gplus-ic" href="https://github.com/kennashka" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-github white-text mr-4"> </i>
              </a>
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row*/}
        </div>
      </div>
      {/* Footer Links */}
      <div className="container text-center text-md-left mt-5">
        {/* Grid row */}
        <div className="row mt-3">
          {/* Grid column */}
          <div className="col-lg-5 mx-auto mb-4">
            {/* Content */}
            <h6 className="text-uppercase font-weight-bold"><i className="fa fa-heart fa-fw" /> Donate</h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}} />
            <p>ETH: 0xf3ED875C7A6217FBD80A676a2FdC10447fA2CcBC </p>
            <p> BTC: 1D5arLsHoPKoJxWhw5Czip9hpKxWNYRC2x </p>
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className=" col-lg-3  mx-auto mb-4">
            {/* Links */}
            <h6 className="text-uppercase font-weight-bold">Services</h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}} />
            <p>	<a href="#!">CMS Development</a>	</p>
            <p><a href="#!">Hosting &amp; Security</a>	</p>
            <p>	<a href="#!">Cloud Migration</a> </p>
            <p> <a href="#!">Ecommerce</a> </p>
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className="col-lg-4 mx-auto mb-md-0 mb-4">
            {/* Links */}
            <h6 className="text-uppercase font-weight-bold">Contact</h6>
            <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px'}} />
            <p className="subtitle">
              <i className="fa fa-map-marker mr-3" /> 127.0.0.1</p>				<p className="subtitle">
              <i className="fa fa-envelope mr-3" /><a href="mailto:admin@kennashka.com"> mail@kennashka.com</a></p>
            <p>
            </p></div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
      {/* Footer Links */}
      {/* Copyright */}
      <div className="footer-copyright text-center py-3">© {getYear()} Copyright: 
        <a href="https://wwww.kennashka.com/">  www.kennashka.com</a>
      </div>
      {/* Copyright */}
    </footer>
    )
}

export default Footer;