import React from 'react';

     
     
     
   const Card = (props) =>{ 
         // destructuring to access the value of props
         // values to be acccessed by props: image, href for project link, and href for the site, status: Live or In developement,
         // const appTitle = {  Calculator App, Covid-19 App, To Do/Reminder App, Kenya Blog (In Dev), Grade Average App, News App }
// 5 five more properties to destructure.
    const { CardImage ='',
       appTitle=[],
   } = props;


return (



     
     <div className="col m4">
     <div className="card hoverable">

     <div className="card-image waves-effect waves-block waves-light">
     <img className="activator" 
     src= {CardImage} alt='calc'
     style={{maxWidth: '100px'
     , maxHeight: '100px'}} />
     </div>
     
     <div className="card-content">
     
     
         
    <div className="icon">
     <span className="express">
     <span />
     </span>
     </div>

     <div className="icon">
     <span className="node">
     <span />
     </span>
     </div>
        <div className="icon">
     <span className="react">
     <span />
     </span>
     </div>

     
      
      <span className="card-title activator grey-text text-darken-4">
{appTitle.name}     

 <i className="material-icons right">more_vert</i>
      </span>
      
      <div>
 <span>
    <a className="website" href={appTitle.website}>Website</a>
  </span>

  <span>
      <a className="github" href={appTitle.github}>Github</a> 
        </span> 
  </div> 
   </div>
 <div className="card-reveal">
  <span className="card-title grey-text text-darken-4"> {appTitle.name}     
 <i className="material-icons right">close</i></span>
  <div>
      <span className="status"> {appTitle.status}</span>
 </div>
 <p> {appTitle.description}</p>
 </div>
</div>
  </div>


     )};

     export default Card;