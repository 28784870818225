import React, {useState, useEffect} from 'react';
import Header from '../core/Header';
import Footer from '../core/Footer';
// function News() {
  

  const News = () => {
       
    //state
const [news, setNews ] = useState([])
const [searchQuery, setSearchQuery] = useState('react');
const [url, setUrl] = useState('https://hn.algolia.com/api/v1/search?query=react');
const [loading, setLoading] = useState(false)

//  fetch news method

const fetchNews  = () =>{
  // set loading to true
  setLoading(true)
  fetch(url)
  .then(result => result.json())
  // .then(data => console.log(data))
  .then(data => (setNews(data.hits), setLoading(false)))
  .catch(error => console.log(error));
}

useEffect(()=>{
 fetchNews();
}, [url]);

// event target value

const handleChange = (e) =>{
setSearchQuery(e.target.value)
}

const handleSubmit = (e) =>{
  e.preventDefault()
  setUrl(`https://hn.algolia.com/api/v1/search?query=${searchQuery}`)
  };

  const showLoading = () => (loading ? <h2>Loading...</h2> : "")

const searchForm = () => (
  <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">

  <form class="form-group justify-content-center" onSubmit={handleSubmit}>
    <div class="col-sm-5">

  <input type="text" class="form-control" value={searchQuery} onChange={handleChange}></input>
  
  <button> Search </button>
  </div>
</form>



</div>

)

const showNews = () => (
  
  news.map((n, i) => (<p key ={i}>{n.title}</p>
    ))
) 
        
  return (
    <div>
  
<Header>

</Header>
  <h4 className="text-center p-4">News App</h4>
  <div class="container">
    {showLoading()}
    {searchForm()}
    {showNews()}
  </div>
   <Footer>

   </Footer>
   </div>
  );

  }
export default News;
